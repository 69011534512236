import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import React from 'react'
import List from '../../classes/List'
import CommonHelper from '../../helpers/CommonHelper'
import AppButton from '../layout/AppButton'
import Container from '../layout/Container'
import DataList from '../layout/DataList'
import { showWarnMessage } from './Message'
import Modal from './Modal'
import ResponsiveColumn from './ResponsiveColumn'

export default class FiltroModal extends List {
  constructor (props) {
    super(props, props.model, props.service, '', '', 20)

    this.state = {
      ...this.state,
      stringCampos: '',
      expandFilters: false
    }
  }

  componentDidMount () {
    this.addEventListeners()
    this.resetDescricaoFiltro()
    let stringCampos = ''
    this.props.columns?.forEach(column => {
      const { pesquisavel = true } = column
      const campo = column.nome ? column.nome : column.label

      if (pesquisavel && campo !== 'Código') {
        stringCampos += (', ' + campo)
      }
    })
    stringCampos = stringCampos.replace(', ', '')
    const { filtersDefault } = this.props
    if (filtersDefault) {
      const filter = {}

      Object.getOwnPropertyNames(filtersDefault).forEach((propertyName) => {
        filter[propertyName] = filtersDefault[propertyName]
      })

      this.setState({ filter })
    } else {
      this.setState({ filter: { descricaoFiltro: this.state.filter.descricaoFiltro, descricaoCodigo: this.state.filter.descricaoCodigo } })
    }
    this.setState({ stringCampos })
  }

  componentDidUpdate (prevProps) {
    if (!prevProps.visible && this.props.visible) {
      const { filtersDefault } = this.props
      if (filtersDefault) {
        const filter = {}

        Object.getOwnPropertyNames(filtersDefault).forEach((propertyName) => {
          filter[propertyName] = filtersDefault[propertyName]
        })

        if (this.props.semFiltroInicial) {
          this.setState({ filter }, this.filtrarCasoNaoTiverRegistros)
        } else {
          this.setState({ filter })
        }
      } else {
        this.setState({ filter: { descricaoFiltro: this.state.filter.descricaoFiltro, descricaoCodigo: this.state.filter.descricaoCodigo } }, this.filtrarCasoNaoTiverRegistros)
      }
    }
  }

  filtrarCasoNaoTiverRegistros () {
    if (!this.state.list.length) {
      this.onFilter()
    }
  }

  handleKeyDown = (event, pesquisaParaPeca) => {
    if (event.key === 'Enter') {
      this.filtrarESelecionar(pesquisaParaPeca)
    }
  }

  filtrarESelecionar = async (pesquisaParaPeca) => {
    await this.onFilter()
    if (pesquisaParaPeca) {
      if (this.state.list.length === 1) {
        this.handleSelect(this.state.list[0])
      }
    }
  }

  handleSelect = (value) => {
    try {
      this.props.onSelect({ value })
      this.props.hide()
    } catch (error) {
      showWarnMessage(error.message)
    }
  }

  getColumns () {
    return this.props.columns?.map(column => {
      const campo = column.campo ? column.campo : column.key
      const label = column.nome ? column.nome : column.label
      return (
        <Column
          key={campo}
          field={campo}
          header={label}
          sortable
          body={(data) => {
            const propriedades = campo.split('.')
            let valor = propriedades.reduce((a, prop) => (column?.preload ? (a[column?.preload]?.[prop] ?? '') : a[prop]), data)
            // console.log(valor)
            if (valor === true) {
              valor = 'Sim'
            } else if (valor === false) {
              valor = 'Não'
            }

            const valueDaColuna = column.format ? column.format(valor) : valor
            if (campo.includes('button')) {
              return <ResponsiveColumn column={label} value={
                <Button
                  label={column.buttonLabel}
                  icon={column.buttonClassName}
                  onClick={async () => await column.buttonOnClick(data)}
                />
              }
              />
            }

            return <ResponsiveColumn column={label} value={valueDaColuna} />
          }}
          style={column.style
            ? column.style : (campo.includes('codigo')
              ? { width: (column.width || '10%'), textAlign: 'center' }
              : { width: (column.width || '100px'), textAlign: 'center' })
          }
        />
      )
    })
  }

  render () {
    const props = this.props
    const state = this.state
    const prop = { filter: state.filter, changeFilter: (filter) => this.setState({ filter }) }
    const pesquisaParaPeca = props.title.includes('peças') || props.title.includes('produto')

    // Configuração condicional dos eventos
    const onSelectionChange = pesquisaParaPeca
      ? (e) => {
        // Apenas registra a seleção sem finalizar
        this.setState({ selectedValue: e.value })
      }
      : (e) => {
        if (!e.originalEvent.target.className.includes('info-selection')) {
          this.handleSelect(e.value)
        }
      }

    const onRowDoubleClick = pesquisaParaPeca
      ? (e) => {
        // Apenas permite seleção no duplo clique
        this.handleSelect(e.data)
      }
      : undefined // Não faz nada se pesquisaParaPeca for false

    return (
      <Modal
        header={props.title}
        className={props.full ? 'w-full' : 'md:w-8 w-10'}
        width={props.width || '65'}
        visible={props.visible}
        onHide={props.hide}
      >
        <Container className='h-full' >
          <div className="grid align-items-center">
            <div className="field col-6 md:col-2 ">
              <span className="p-input-icon-left w-full">
                <i className="pi pi-search" style={{ color: '#8a9099' }} />
                <InputText
                  placeholder={'Código'}
                  name="descricaoCodigo"
                  value={state.filter.descricaoCodigo || ''}
                  onChange={this.handleChangeFilter}
                  className="w-full"
                  autoFocus={true}
                  onKeyDown={(event) => this.handleKeyDown(event, pesquisaParaPeca)}
                />
              </span>
            </div>
            {pesquisaParaPeca && ( // Apenas exibe para peças
              <div className="field col-6 md:col-2 ">
                <span className="p-input-icon-left w-full">
                  <i className="pi pi-search" style={{ color: '#8a9099' }} />
                  <InputText
                    placeholder={'Código de barras/EAN'}
                    name="codigoBarrasEAN"
                    value={state.filter.codigoBarrasEAN || ''}
                    onChange={this.handleChangeFilter}
                    className="w-full"
                    onKeyDown={(event) => this.handleKeyDown(event, pesquisaParaPeca)}
                  />
                </span>
              </div>
            )}
            <div className={`
                field col-12 
                md:col-${pesquisaParaPeca ? '6' : '8'}
              `}
            >
              <span className="p-input-icon-left w-full">
                <i className="pi pi-search" style={{ color: '#8a9099' }} />
                <InputText
                  placeholder={state.stringCampos}
                  name="descricaoFiltro"
                  value={state.filter.descricaoFiltro || ''}
                  onChange={this.handleChangeFilter}
                  className="w-full"
                  autoFocus={true}
                  onKeyDown={(event) => this.handleKeyDown(event, pesquisaParaPeca)}
                />
                {props.expandedFiltersTemplate && (
                  <i
                    className="pi pi-sliders-h cursor-pointer"
                    style={{ color: '#8a9099', right: '18px' }}
                    onClick={() => this.setState({ expandFilters: !state.expandFilters })} />
                )}
              </span>
            </div>
            <div className="field col-12 md:col-2" style={{ padding: '0.5rem' }}>
              <AppButton
                icon="pi pi-search"
                label="Filtrar"
                onClick={() => this.filtrarESelecionar(pesquisaParaPeca)}
                className="w-full" />
            </div>
            {state.expandFilters && (
              <>
                {props.expandedFiltersTemplate(prop)}
                <div className="field col-12 flex justify-content-end mb-4">
                  <AppButton
                    label="Buscar"
                    icon="pi pi-search"
                    onClick={() => this.filtrarESelecionar(pesquisaParaPeca)} />
                </div>
              </>
            )}
          </div>
          <DataList
            data={state.list}
            onSelectionChange={onSelectionChange}
            onRowDoubleClick={onRowDoubleClick}
            className='h-full w-full'
            hidePaginator={props.hidePaginator}
            rows={state.rows}
            totalRecords={state.totalRecords}
            first={state.first}
            onPage={this.onPageChange}
            responsive={true}
            page={state.page}
            scrollable={props.horizontalScroll}
            scrollHeight={`${!CommonHelper.isNotebook() ? '550px' : '350px'}`}
            scrollDirection={props.horizontalScroll ? 'horizontal' : ''}
          >
            {this.getColumns()}
          </DataList>
        </Container>
      </Modal>
    )
  }
}
