import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import SelectInput from '../../../../components/inputs/SelectInput'
import DataListSimples from '../../../../components/layout/DataListSimples/DataListSimples'
import DropdownMenu from '../../../../components/layout/DropdownButton/DropdownMenu'
import { baseForm } from '../../../../components/utils/BaseForm'
import { showErrorMessage, showSuccessMessage, showWarnMessage } from '../../../../components/utils/Message'
import { getPecaBalcaoDTO, postPecaBalcaoDTO } from '../../../../dtos/vendas/orcamentoBalcao/PecaBalcaoDTO'
import CommonHelper from '../../../../helpers/CommonHelper'
import { formatToCurrency, formatToPercentage } from '../../../../helpers/formaters'
import { createColumns } from '../../../../helpers/tableConfigs'
import PermissaoService from '../../../../services/cadastro/pessoa/PermissaoService'
import ProdutoService from '../../../../services/cadastro/produto/ProdutoService'
import AplicarDescontoModal from '../modals/AplicarDescontoModal'
import DadosPecasOpvModal from './DadosPecasOpvModal'

function DadosPecasOpv ({ form, edicaoHabilitada, camposObrigatorios, calculaValoresOrcamento, tiposPreco, pecaBalcaoValidator, pecaBalcaoCamposObrigatorios, atualizaDescontoPeca, calculaValorTotalPeca, handleChangeTipoPreco, gerarVendaPerdidaPecCancelada, permitirDescontoByTipoPreco, limparCampoDesconto }) {
  const [visiblePecaModal, setVisiblePecaModal] = useState(false)
  const [visibleModalAplicarDesconto, setVisibleModalAplicarDesconto] = useState(false)
  const [pecaBalcaoSelecionada, setPecaBalcaoSelecionada] = useState(null)
  const [permissao197AlterarDescontoQtd, setPermissao197AlterarDescontoQtd] = useState(false)

  function insertOrUpdatePeca () {
    form.setFieldValue('dahent_opv', null)
    form.setFieldValue('usuent_opv', null)
    form.setFieldValue('issepa_opv', 0)

    if (pecaBalcaoSelecionada) {
      editarPeca()
    } else {
      adicionarPeca()
    }

    pecaBalcaoForm.resetForm()
    hidePecaBalcaoModal()
  }

  function adicionarPeca () {
    const pecasBalcao = form.values.pecasBalcao
    pecasBalcao.push(postPecaBalcaoDTO(pecaBalcaoForm.values))

    pecasBalcao.sort((a, b) => Number(a.iscan_peo) - Number(b.iscan_peo))

    form.setFieldValue('pecasBalcao', pecasBalcao)
    calculaValoresOrcamento(pecasBalcao)
  }

  function editarPeca () {
    const pecasBalcao = form.values.pecasBalcao

    const indexPecaBalcao = pecasBalcao.indexOf(pecaBalcaoSelecionada)

    pecasBalcao.splice(indexPecaBalcao, 1, postPecaBalcaoDTO(pecaBalcaoForm.values))

    form.setFieldValue('pecasBalcao', pecasBalcao)
    calculaValoresOrcamento(pecasBalcao)
  }

  const pecaBalcaoForm = baseForm({
    initialValues: getPecaBalcaoDTO(),
    validationSchema: pecaBalcaoValidator,
    onSubmit: insertOrUpdatePeca
  })

  useEffect(async () => {
    if (pecaBalcaoSelecionada) {
      pecaBalcaoForm.setValues(getPecaBalcaoDTO(pecaBalcaoSelecionada))
    } else {
      pecaBalcaoForm.setValues(getPecaBalcaoDTO())
    }
    const permissao197 = await PermissaoService.getByCodigo(197)
    setPermissao197AlterarDescontoQtd(permissao197)
  }, [pecaBalcaoSelecionada])

  const abrirModalAdicionarPecaAtalho = (event) => {
    if (event.altKey && event.key === 'a' && edicaoHabilitada) {
      setVisiblePecaModal(true)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', abrirModalAdicionarPecaAtalho)
    return () => {
      window.removeEventListener('keydown', abrirModalAdicionarPecaAtalho)
    }
  }, [edicaoHabilitada])

  async function atualizaPrecos () {
    const pecasBalcao = form.values.pecasBalcao
    try {
      for (const peca of pecasBalcao) {
        if (!peca.iscan_peo) {
          const codEmp = form.values.codemp_opv
          const codTipoPreco = form.values.tipoPreco.codigo_tpr

          const dadosPrecoProduto = await getDadosPrecoProduto(peca.produto, peca.qtd_peo, codEmp, codTipoPreco)
          if (dadosPrecoProduto && dadosPrecoProduto?.preco > 0) {
            peca.cusuni_peo = dadosPrecoProduto.cusmedliq_pre
            peca.valuni_peo = dadosPrecoProduto.preco
            peca.valbru_peo = dadosPrecoProduto.preco
            peca.perdes_peo = dadosPrecoProduto.perdesQtd
            peca.usapreqtd_peo = dadosPrecoProduto.usaPreQtd

            atualizaDescontoPeca(peca)
            calculaValorTotalPeca(peca)
          } else {
            showWarnMessage(`A peça ${peca.codpec_peo} ${peca.descri_peo} não foi atualizada, pois o preço está zerado!`)
          }
        }
      }
      showSuccessMessage('Preços atualizados com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao atualizar os preços dos produtos!')
    }
    calculaValoresOrcamento(pecasBalcao)
    form.setFieldValue('pecasBalcao', pecasBalcao)
  }

  async function getDadosPrecoProduto (produto, quantidade, codEmp, codTipoPreco) {
    let dadosPrecoProduto
    try {
      dadosPrecoProduto = await ProdutoService.getDadosPrecoByTipoPreco(produto.codigo_pro, quantidade, codEmp, codTipoPreco)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar os dados do produto!')
    }

    return dadosPrecoProduto
  }

  async function trocarTipoPreco (event) {
    try {
      await handleChangeTipoPreco(event, form.values.cliente, true)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao trocar o tipo do preço!')
      return false
    }
  }

  const columns = createColumns([
    {
      field: 'codpec_peo',
      header: 'Código',
      width: '10%',
      sortable: false
    },
    {
      field: 'descri_peo',
      header: 'Peça',
      width: '30%',
      customBody: formatDescricaoPeca,
      sortable: false
    },
    {
      field: 'qtd_peo',
      header: 'Quantidade',
      width: '14%',
      customBody: formatQuantidade,
      sortable: false
    },
    {
      field: 'valuni_peo',
      header: 'Valor Unitário',
      width: '14%',
      customBody: formatValorUnitario,
      sortable: false
    },
    {
      field: 'perdes_peo',
      header: 'Desconto %',
      width: '14%',
      customBody: formatPorcentagemDesconto,
      sortable: false
    },
    {
      field: 'valdes_peo',
      header: 'Desconto',
      width: '14%',
      customBody: formatDesconto,
      sortable: false
    },
    {
      field: 'valtot_peo',
      header: 'Valor Total',
      width: '14%',
      customBody: formatValorTotal,
      sortable: false
    }
  ])

  function formatDescricaoPeca (data) {
    const descricaoPeca = `${(data.iscan_peo ? '(Cancelada) ' : '')} ${data.descri_peo.toUpperCase()} - (${data.refpro_peo})`

    return descricaoPeca
  }

  function formatQuantidade (data) {
    return data.qtd_peo + ' ' + data.siguni_peo
  }

  function formatValorUnitario (data) {
    return formatToCurrency(data.valuni_peo)
  }

  function formatPorcentagemDesconto (data) {
    return formatToPercentage(data.perdes_peo)
  }

  function formatDesconto (data) {
    return formatToCurrency(data.valdes_peo)
  }

  function formatValorTotal (data) {
    return formatToCurrency(data.valtot_peo)
  }

  function calculaPercentualDesconto (pecaBalcao) {
    const valorTotalBrutoPecaBalcao = pecaBalcao.qtd_peo * pecaBalcao.valuni_peo

    if (valorTotalBrutoPecaBalcao !== 0) {
      pecaBalcao.perdes_peo = CommonHelper.Round(((pecaBalcao.valdes_peo * 100) / valorTotalBrutoPecaBalcao), 2)
    } else {
      pecaBalcao.perdes_peo = CommonHelper.Round(((pecaBalcao.valdes_peo * 100) / 1), 2)
    }
    calculaValorTotalPeca(pecaBalcao)
  }

  function hidePecaBalcaoModal () {
    setPecaBalcaoSelecionada(null)
    setVisiblePecaModal(false)
    pecaBalcaoForm.resetForm()
  }

  function hideAplicarDescontoModal () {
    setVisibleModalAplicarDesconto(false)
    calculaValoresOrcamento()
    pecaBalcaoForm.resetForm()
  }

  function rowClass (data) {
    return {
      'row-peca-cancelada': data.iscan_peo
    }
  }

  function modalAplicarDesconto () {
    setVisibleModalAplicarDesconto(true)
  }

  function validarDescontoMaiorValorTotal (valorDesconto) {
    let valbru = 0

    form.values.pecasBalcao.forEach(pecaBalcao => {
      valbru += !pecaBalcao.iscan_peo ? pecaBalcao.valuni_peo * pecaBalcao.qtd_peo : 0
    })

    if (valorDesconto > valbru) {
      showWarnMessage('Valor não pode exceder o valor bruto dos produtos')
      return true
    }
    return false
  }

  function ratearDesconto (valorDesconto) {
    let valorBruto = 0.0
    let valorSobrasAjuste = valorDesconto
    if (validarDescontoMaiorValorTotal(valorDesconto)) return false

    limparCampoDesconto()

    if (valorDesconto) {
      form.values.pecasBalcao.forEach(pecaBalcao => {
        valorBruto += !pecaBalcao.iscan_peo ? pecaBalcao.valuni_peo * pecaBalcao.qtd_peo : 0
      })

      for (let pecaBalcao of form.values.pecasBalcao) {
        if (!pecaBalcao.iscan_peo) {
          // Verifica quantos % cada (peça * qtd) representa no total bruto de todas peças
          let perdesPeo = (100 * (pecaBalcao.valuni_peo * pecaBalcao.qtd_peo)) / valorBruto

          // Calcula o valor de desconto de cada peça conforme a porcentagem dela sobre o total
          pecaBalcao.valdes_peo = (valorDesconto / 100) * perdesPeo

          // Acumula as sobras para ajustar o somatório
          valorSobrasAjuste -= pecaBalcao.valdes_peo

          definirUsaPrecoQuantidade(pecaBalcao)
          calculaPercentualDesconto(pecaBalcao)
          calculaValorTotalPeca(pecaBalcao)
        }
      }

      if (valorSobrasAjuste !== 0.00) {
        //Corrige as sobras
        CommonHelper.ajustaValorColuna(form.values.pecasBalcao, 'valdes_peo', valorDesconto, 0.01, 'item["iscan_peo"] == false')
        for (let pecaBalcao of form.values.pecasBalcao) {
          calculaPercentualDesconto(pecaBalcao)
        }
      }
    }

    form.setFieldValue('pecasBalcao', form.values.pecasBalcao)
  }

  function ratearPorcentagemDesconto (percentualDesconto) {
    for (const pecaBalcao of form.values.pecasBalcao) {
      if (!pecaBalcao.iscan_peo) {
        pecaBalcao.perdes_peo = percentualDesconto

        definirUsaPrecoQuantidade(pecaBalcao)
        atualizaDescontoPeca(pecaBalcao)
        calculaValorTotalPeca(pecaBalcao)
      }
    }
    form.setFieldValue('pecasBalcao', form.values.pecasBalcao)
  }

  async function definirUsaPrecoQuantidade (pecaBalcao) {
    // Se permite alterar desconto por quantidade
    if (permissao197AlterarDescontoQtd) {
      // Busca % de desconto por quantidade da API
      const codEmp = form.values.codemp_opv
      const codTipoPreco = form.values.tipoPreco.codigo_tpr
      const dadosPrecoProduto = await ProdutoService.getDadosPrecoByTipoPreco(pecaBalcao.codpec_peo, pecaBalcao.qtd_peo, codEmp, codTipoPreco)

      // Se o % de desconto por quantidade for igual ao informado
      if (dadosPrecoProduto.perdesQtd === pecaBalcao.perdes_peo) {
        pecaBalcao.usapreqtd_peo = true
      } else {
        pecaBalcao.usapreqtd_peo = false
      }
    }
  }

  const itensAcao =
    [{
      label: 'Aplicar desconto',
      command: () => modalAplicarDesconto()
    },
    {
      label: 'Atualizar preços',
      command: async () => await atualizaPrecos()
    }]

  return (
    <div className="formgrid grid fluid">
      <div className='w-full md:flex justify-content-between'>

        <div className="field col-12 md:col-4">
          <SelectInput
            name="tipoPreco"
            label="Tabela de Preço"
            dataKey="codigo_tpr"
            optionLabel="descri_tpr"
            value={form.values.tipoPreco}
            options={tiposPreco}
            onChange={async (e) => await trocarTipoPreco(e)}
            disabled={!edicaoHabilitada}
            camposObrigatorios={camposObrigatorios}
            form={form}
            scrollHeight={300}
          />
        </div>
        <div>
        </div>

        <div className="field col-12 md:col-4 flex-column justify-content-end mg:m-8">
          <div className='flex  sm:w-full justify-content-end'>

            <Button
              label="Adicionar peça"
              className=' mr-2'
              icon="pi pi-plus"
              onClick={() => setVisiblePecaModal(true)}
              disabled={!edicaoHabilitada}
            />
            {edicaoHabilitada &&
              <DropdownMenu
                className='w-6rem'
                items={itensAcao}
                icon="pi pi-ellipsis-h"
              />
            }
          </div>
        </div>

      </div>
      <div className="field col-12">
        <DataListSimples
          value={form.values.pecasBalcao}
          columns={columns}
          selected={pecaBalcaoSelecionada}
          rowClassName={rowClass}
          widthTotalizador={'w-6'}
          totalizadores={[
            { key: 'valdes_peo', label: 'Valor total do desconto', formatFunction: formatToCurrency, condition: (row) => !row.iscan_peo },
            { key: 'valtot_peo', label: 'Valor total das peças', formatFunction: formatToCurrency, condition: (row) => !row.iscan_peo }
          ]}
          onSelect={(e) => {
            setPecaBalcaoSelecionada(e.value)
            setVisiblePecaModal(true)
          }}
        />
      </div>
      <DadosPecasOpvModal
        visible={visiblePecaModal}
        onHide={() => hidePecaBalcaoModal()}
        camposObrigatorios={pecaBalcaoCamposObrigatorios}
        pecaBalcaoForm={pecaBalcaoForm}
        orcamentoBalcaoForm={form}
        pecaBalcaoSelecionada={pecaBalcaoSelecionada}
        edicaoHabilitada={edicaoHabilitada}
        calculaValoresOrcamento={calculaValoresOrcamento}
        form={pecaBalcaoForm}
        setPecasBalcao={(pecasBalcao) => form.setFieldValue('pecasBalcao', pecasBalcao)}
        calculaValorTotalPeca={calculaValorTotalPeca}
        atualizaDescontoPeca={atualizaDescontoPeca}
        getDadosPrecoProduto={getDadosPrecoProduto}
        gerarVendaPerdidaPecCancelada={gerarVendaPerdidaPecCancelada}
        permitirDescontoByTipoPreco={permitirDescontoByTipoPreco}
      />
      <AplicarDescontoModal
        visible={visibleModalAplicarDesconto}
        edicaoHabilitada={edicaoHabilitada}
        onHide={() => hideAplicarDescontoModal()}
        form={form}
        setPecasBalcao={(pecasBalcao) => form.setFieldValue('pecasBalcao', pecasBalcao)}
        ratearDesconto={ratearDesconto}
        ratearPorcentagemDesconto={ratearPorcentagemDesconto}
        permitirDescontoByTipoPreco={permitirDescontoByTipoPreco}
      />
    </div>
  )
}

export default DadosPecasOpv
