import React, { useEffect, useState } from 'react'
import AutoCompleteInput from '../../../../components/inputs/AutoCompleteInput'
import CheckboxInput from '../../../../components/inputs/CheckboxInput'
import TextInput from '../../../../components/inputs/TextInput'
import TabMenuContainer from '../../../../components/layout/TabMenuContainer/TabMenuContainer'
import TabMenuPage from '../../../../components/layout/TabMenuContainer/TabMenuPage'
import { showErrorMessage, showWarnMessage } from '../../../../components/utils/Message'
import Modal from '../../../../components/utils/Modal'
import PreferencesButton from '../../../../components/utils/PreferencesButton'
import TablePreferences from '../../../../components/utils/TablePreferences'
import { getGrupoProdutoDTO } from '../../../../dtos/cadastro/produto/GrupoProdutoDTO'
import { getProdutoDTO } from '../../../../dtos/cadastro/produto/ProdutoDTO'
import { getMarcaDTO } from '../../../../dtos/cadastro/veiculo/MarcaDTO'
import CommonHelper from '../../../../helpers/CommonHelper'
import { formatToCurrency } from '../../../../helpers/formaters'
import { getTableConfig } from '../../../../helpers/tableConfigs'
import { getStore } from '../../../../redux/store'
import PermissaoService from '../../../../services/cadastro/pessoa/PermissaoService'
import GrupoProdutoService from '../../../../services/cadastro/produto/GrupoProdutoService'
import ProdutoService from '../../../../services/cadastro/produto/ProdutoService'
import MarcaService from '../../../../services/cadastro/veiculo/MarcaService'
import ConfigService from '../../../../services/configuracao/ConfigService'
import '../../consultaEstoque/_consultaEstoqueForm.scss'
import DadosProdutoDasOutrasEmpresas from '../../orcamentoBalcao/orcamentoBalcaoFormPages/DadosProdutoDasOutrasEmpresasModal'
import PrecosEmpresasModal from '../../orcamentoBalcao/orcamentoBalcaoFormPages/PrecosEmpresasModal'
import EstoqueList from './EstoqueList'
import OrcamentoOSList from './OrcamentoOSList'
import PedidoCompraList from './PedidoCompraList'
import ValoresProdutoDasOutrasEmpresas from './ValoresProdutoOutraEmpresaModal'

function ConsultaEstoqueForm ({
  form,
  edicaoHabilitada,
  camposObrigatorios,
  hide,
  visible,
  modal = false

}) {
  const [sugestaoProdutos, setSugestaoProdutos] = useState([])
  const [permissao45ItensSemEstoque, setPermissao45ItensSemEstoque] = useState(false)
  const [itensComEstoque, setItensComEstoque] = useState(false)
  const [apenasDisponiveis, setApenasDisponiveis] = useState(false)
  const [filtrosDefault, setFiltrosDefault] = useState({})
  const [visibleValoresOutrasEmpresasModal, setVisibleValoresOutrasEmpresasModal] = useState(false)
  const [visibleDadosOutrasEmpresasModal, setVisibleDadosOutrasEmpresasModal] = useState(false)
  const [visiblePrecosEmpresasModal, setVisiblePrecosEmpresasModal] = useState(false)

  const [produtoParaVisualizarDados, setProdutoParaVisualizarDados] = useState(null)
  const [aRetirar, setARetirar] = useState(false)
  const [reservada, setReservada] = useState(true)
  const [aplicada, setAplicada] = useState(true)
  const [codigoPro, setCodigoPro] = useState(0)
  const [visiblePreferencesModal, setVisiblePreferencesModal] = useState(false)
  const [nomePrecoPersonalizado1, setNomePrecoPersonalizado1] = useState('')
  const [nomePrecoPersonalizado2, setNomePrecoPersonalizado2] = useState('')
  const [nomePrecoPersonalizado3, setNomePrecoPersonalizado3] = useState('')

  useEffect(async () => {
    const permissao45 = await PermissaoService.getByCodigo(45)
    setARetirar(await ConfigService.getValor(1396))
    setPermissao45ItensSemEstoque(permissao45)
    setFiltrosDefault({ somenteComEstoque: !permissao45 || itensComEstoque, apenasDisponiveis: apenasDisponiveis, consultaEstoque: true })
    const nomePrecoPersonalizado1 = await ConfigService.getValor(3091) || 'Personalizado 1'
    const nomePrecoPersonalizado2 = await ConfigService.getValor(3092) || 'Personalizado 2'
    const nomePrecoPersonalizado3 = await ConfigService.getValor(3093) || 'Personalizado 3'
    setPermissao45ItensSemEstoque(permissao45)
    setNomePrecoPersonalizado1(nomePrecoPersonalizado1)
    setNomePrecoPersonalizado2(nomePrecoPersonalizado2)
    setNomePrecoPersonalizado3(nomePrecoPersonalizado3)
  }, [])

  function getDescricaoPeca () {
    let descricaoPeca = form.values.produto.descri_pro
    let referencaPeca = form.values.produto.refere_pro

    if (referencaPeca) {
      if (typeof descricaoPeca !== 'object') {
        return `${descricaoPeca} - (${referencaPeca})`
      }
    } else {
      return typeof descricaoPeca !== 'object' && descricaoPeca
    }
  }

  async function sugerirProdutos () {
    try {
      const produtos = await ProdutoService.filterSugestao(`descricaoFiltro=${form.values.produto.descri_pro}${!permissao45ItensSemEstoque ? `&somenteComEstoque=${!permissao45ItensSemEstoque}` : ''}`)

      if (produtos?.data.length <= 0) {
        showWarnMessage('Produto não encontrado!')
      }
      setSugestaoProdutos(produtos.data)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um produto!')
    }
  }

  function handleChangeProduto (event) {
    form.setFieldValue('produto', event.target.value)
    form.setFieldValue('produto.descri_pro', event.target.value)
  }

  useEffect(async () => {
    await getEstoque(codigoPro)
  }, [aplicada, reservada, aRetirar])

  const SugestaoTemplate = (data) => (
    <span><b>{data.refere_pro}</b> - {data.descri_pro}</span>
  )
  //word-wrap: 'break-word'
  // {width: (column.width || '100px'), textAlign: 'center', word-wrap: 'break-word'}
  const columns = [
    {
      key: 'codigo_pro',
      label: 'Código',
      width: '100px',
      pesquisavel: false,
      enabled: true
    },
    { key: 'refere_pro', campo: 'refere_pro', nome: 'Referência', label: 'Referência', width: '150px', pesquisavel: false, enabled: true, style: { width: '150px', textAlign: 'center', overflowWrap: 'break-word' } },
    { key: 'reffab_fpr', campo: 'reffab_fpr', nome: 'Ref. Fábrica ', label: 'Ref. Fábrica', width: '150px', pesquisavel: false, enabled: true, style: { width: '150px', textAlign: 'center', overflowWrap: 'break-word' } },
    { key: 'descri_pro', campo: 'descri_pro', nome: 'Descrição', label: 'Descrição', width: '600px', pesquisavel: true, enabled: true },
    { key: 'descri_mar', campo: 'descri_mar', nome: 'Marca', label: 'Marca', width: '150px', pesquisavel: false, enabled: true },
    { key: 'descri_grp', campo: 'descri_grp', nome: 'Grupo', label: 'Grupo', width: '150px', pesquisavel: false, enabled: true },
    {
      key: 'estatu_pre',
      campo: 'estatu_pre',
      nome: 'Estoque',
      label: 'Estoque',
      width: '150px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'qtdapl_pre',
      campo: 'qtdapl_pre',
      nome: 'Aplicadas',
      label: 'Aplicadas',
      width: '150px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'qtdres_pre',
      campo: 'qtdres_pre',
      nome: 'Reservadas',
      label: 'Reservadas',
      width: '150px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'qtdtra_pre',
      campo: 'qtdtra_pre',
      nome: 'Em trânsito',
      label: 'Em trânsito',
      width: '150px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'qtddisponivel',
      campo: 'qtddisponivel',
      nome: 'Qtd. disponível',
      label: 'Qtd. disponível',
      width: '150px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'preco_pre',
      campo: 'preco_pre',
      nome: 'Preço varejo',
      label: 'Preço varejo',
      format: formatToCurrency,
      width: '150px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'prepro_ppr',
      campo: 'prepro_ppr',
      nome: 'Preço promocional',
      label: 'Preço promocional',
      format: formatToCurrency,
      width: '150px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'preata_pre',
      campo: 'preata_pre',
      nome: 'Atacado',
      label: 'Atacado',
      format: formatToCurrency,
      width: '150px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'preeco_pre',
      campo: 'preeco_pre',
      nome: 'E-commerce',
      label: 'E-commerce',
      format: formatToCurrency,
      width: '150px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'preper1_pre',
      campo: 'preper1_pre',
      nome: nomePrecoPersonalizado1,
      label: nomePrecoPersonalizado1,
      format: formatToCurrency,
      width: '200px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'preper2_pre',
      campo: 'preper2_pre',
      nome: nomePrecoPersonalizado2,
      label: nomePrecoPersonalizado2,
      format: formatToCurrency,
      width: '200px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'preper3_pre',
      campo: 'preper3_pre',
      nome: nomePrecoPersonalizado3,
      label: nomePrecoPersonalizado3,
      format: formatToCurrency,
      width: '200px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'enderecamento',
      campo: 'enderecamento',
      nome: 'Endereçamento',
      label: 'Endereçamento',
      width: '200px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'obs_pro',
      campo: 'obs_pro',
      format: getObservacao,
      nome: 'Observação',
      label: 'Observação',
      width: '600px',
      pesquisavel: true,
      enabled: true
    },
    {
      key: 'codbar_pro',
      campo: 'codbar_pro',
      nome: 'Código de barras',
      label: 'Código de barras',
      width: '200px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'codean_pro',
      campo: 'codean_pro',
      nome: 'Código EAN',
      label: 'Código EAN',
      width: '200px',
      pesquisavel: false,
      enabled: true
    },
    {
      key: 'buttonPreco',
      campo: 'buttonPreco',
      nome: 'Preços',
      label: 'Preços',
      width: '250px',
      pesquisavel: false,
      enabled: true,
      buttonClassName: 'pi pi-info-circle info-selection',
      buttonOnClick: async (data) => {
        setProdutoParaVisualizarDados(data)
        setVisiblePrecosEmpresasModal(true)
      }
    },
    {
      key: 'button',
      campo: 'button',
      nome: 'Dados das outras empresas',
      label: 'Dados das outras empresas',
      width: '250px',
      pesquisavel: false,
      enabled: true,
      buttonClassName: 'pi pi-info-circle info-selection',
      buttonOnClick: async (data) => {
        setProdutoParaVisualizarDados(data)
        setVisibleDadosOutrasEmpresasModal(true)
      }
    }
  ]
  function getObservacao (data) {
    return data.slice(0, 200)
  }

  async function onSelectProduto (e) {
    let produto = e.value
    setProdutoParaVisualizarDados(produto)
    setCodigoPro(produto.codigo_pro)
    await getEstoque(produto.codigo_pro)
  }

  async function getEstoque (codigo_pro) {
    let produtoEstoque = await ProdutoService.getConsultaEstoque(codigo_pro, getStore().empresaLogada.codigo_emp, aRetirar, aplicada, reservada)
    let precoPromocional = await selectPrecoPromocional(codigo_pro)
    produtoEstoque.prepro_pre = precoPromocional ? produtoEstoque.produtoEmpresa.prepro_pre : 0
    produtoEstoque.dadosEstoque.qtdReservada = getQtdReservada(produtoEstoque.qtdReservada[0])
    form.setFieldValue('produto', produtoEstoque.produtoEmpresa)
    form.setFieldValue('dadosEstoque', produtoEstoque.dadosEstoque)
    form.setFieldValue('qtdReservada', produtoEstoque.qtdReservada[0])
    form.setFieldValue('pedidoPeca', produtoEstoque.pedidoPeca)
  }

  async function selectPrecoPromocional (codigo_pro) {
    const produtos = await ProdutoService.getPrecoPromocao(codigo_pro, getStore().empresaLogada.codigo_emp)
    return produtos
  }
  function getQtdReservada (pedidos) {
    let qtdReservada = 0
    if (pedidos) {
      pedidos.map((qtdRes) => {
        qtdReservada += qtdRes.qtdres_pre
      })
    }
    return qtdReservada
  }

  function ProdutoExpandedFiltersTemplate (props) {
    const handleSomenteItensEstoque = () => {
      props.changeFilter({
        ...props.filter,
        somenteComEstoque: !itensComEstoque
      })
      setItensComEstoque(!itensComEstoque)
    }
    const handleApenasDisponiveis = () => {
      props.changeFilter({
        ...props.filter,
        apenasDisponiveis: !apenasDisponiveis
      })
      setApenasDisponiveis((prevState) => !prevState)
    }
    return (
      <>
        <div className="w-full optionTable" >
          <PreferencesButton onClick={() => setVisiblePreferencesModal(true)} />
        </div>
        <div className="field col-12 md:col-6">
          <TextInput
            label="Marca"
            placeholder="Selecione uma marca"
            value={props.filter.marca?.descri_mar || form.values.marca?.descri_mar}
            className="inputfield w-full"
            //FiltroModal
            onSelect={(e) => props.changeFilter({
              ...props.filter,
              marca: e.value,
              codigo_mar: e.value?.codigo_mar
            })}
            filtrotitle="Pesquisa de marca"
            service={MarcaService}
            model={getMarcaDTO}
            primarykeyname="codigo_mar"
            columns={[
              { campo: 'codigo_mar', nome: 'Código' },
              { campo: 'descri_mar', nome: 'Descrição' }
            ]}
          />

        </div>
        <div className="field col-12 md:col-6">
          <TextInput
            label="Grupo"
            placeholder="Selecione um grupo"
            value={props.filter.grupo?.descri_grp || form.values.grupo?.descri_grp}
            className="inputfield w-full"
            //FiltroModal
            onSelect={(e) => props.changeFilter({
              ...props.filter,
              grupo: e.value,
              codigo_grp: e.value?.codigo_grp
            })}
            filtrotitle="Pesquisa de grupo"
            service={GrupoProdutoService}
            model={getGrupoProdutoDTO}
            primarykeyname="codigo_mar"
            columns={[
              { campo: 'codigo_grp', nome: 'Código' },
              { campo: 'descri_grp', nome: 'Descrição' }
            ]}
          />

        </div>
        <div className="field col-12 md:col-4">
          <TextInput
            label="Referência"
            placeholder="Referência do produto"
            value={props.filter.referencia}
            className="inputfield w-full"
            onChange={(e) => props.changeFilter({
              ...props.filter,
              referencia: e.target.value
            })}
          />
        </div>
        <div className="field col-12 md:col-4">
          <TextInput
            label="Referência de fábrica"
            placeholder="Referência de fábrica do produto"
            value={props.filter.referenciaFabrica}
            className="inputfield w-full"
            onChange={(e) => {
              props.changeFilter({
                ...props.filter,
                referenciaFabrica: e.target.value
              })
            }
            }
          />
        </div>

        <div className="field col-12 md:col-4">
          <CheckboxInput label={'Somente itens com estoque'}
            value={itensComEstoque}
            checked={itensComEstoque || !permissao45ItensSemEstoque}
            disabled={!permissao45ItensSemEstoque}
            onChange={handleSomenteItensEstoque} />

          <CheckboxInput label={'Somente disponíveis'}
            value={apenasDisponiveis}
            checked={apenasDisponiveis}
            onChange={handleApenasDisponiveis} />
        </div>

      </>
    )
  }

  const getActiveColumnsKeys = (columns, tableName) => {
    const preferenciasAtuais = getTableConfig(tableName)
    if (preferenciasAtuais) {
      return getActiveColumnsKeysByTablePreferences(preferenciasAtuais)
    }

    return getDefaultActiveColumnsKeys(columns)
  }
  function getActiveColumnsKeysByTablePreferences (preferenciasAtuais) {
    const activeColumns = preferenciasAtuais.filter(preferencia => preferencia.enabled)
    return activeColumns.map(activeColumn => activeColumn.key)
  }

  const getDefaultActiveColumnsKeys = (columns) => {
    const activeColumnsKeys = []

    columns.forEach(column => {
      const { enabled = true } = column

      if (enabled)
        activeColumnsKeys.push(column.key)
    })

    return activeColumnsKeys
  }
  const getColunasHabilitadas = (columns, tableName) => {
    try {
      const activeColumnsKeys = getActiveColumnsKeys(columns, tableName)
      const colunas = []
      activeColumnsKeys.forEach(activeColumnKey => {
        const foundColumn = columns.find(column => column.key === activeColumnKey)
        if (foundColumn) {
          colunas.push(foundColumn)
        }
      })
      if (colunas.length === 0) {
        return columns
      } else {
        return colunas
      }
    } catch {
      localStorage.removeItem('tablesConfig')
    }
  }

  function ComponentConsultaEstoque () {
    return (
      <>
        {!modal
          ? CommonHelper.isDesktop() ? <h4>{`Consulta de estoque ${form.values?.produto?.codigo_pro ? ' - ' + form.values?.produto?.codigo_pro : ''}`}</h4>
            : <h3>{`Consulta de estoque ${form.values?.produto?.codigo_pro ? ' - ' + form.values?.produto?.codigo_pro : ''}`}</h3>
          : ''}
        {!modal ? <div className="border" /> : '' }
        <div className="h-56 mb-4 md:p-4 bg-white sombra" style={ { width: `${modal ? '80vw' : '100vw'}` } }>
          <div className="md:flex  w-full ">
            <div className="field col-12 md:col-8">
              <AutoCompleteInput
                //AutoComplete
                label="Produto"
                name="produto.codigo_pro"
                field="descri_pro"
                value={form.values.produto?.descri_pro && getDescricaoPeca()}
                placeholder="Nome do produto"
                disabled={!edicaoHabilitada}
                onSelect={async (e) => await onSelectProduto(e)}
                suggestions={sugestaoProdutos}
                completeMethod={sugerirProdutos}
                onChange={handleChangeProduto}
                camposObrigatorios={camposObrigatorios}
                horizontalScroll={CommonHelper.isDesktop()}
                width='65'
                form={form}
                itemTemplate={SugestaoTemplate}
                //FiltroModal
                filtrotitle="Pesquisa de produto"
                expandedFiltersTemplate={ProdutoExpandedFiltersTemplate}
                service={ProdutoService}
                model={getProdutoDTO}
                panelStyle={{ width: `${CommonHelper.isDesktop() ? '300px' : ''}`, left: `${CommonHelper.isDesktop() ? '607px' : ''}` }}
                primarykeyname="codigo_pro"
                filtersDefault={filtrosDefault}
                columns={getColunasHabilitadas(columns, 'produtos')}
                semFiltroInicial={false}
                abriModalAtalho
                full
              />
              <TablePreferences
                title="Preferências da tabela de peças"
                tableName="produtos"
                columns={columns}
                visible={visiblePreferencesModal}
                hide={() => setVisiblePreferencesModal(false)}
              />
            </div>
            <div className="field col-12 md:col-4">

              <TextInput
                camposObrigatorios={camposObrigatorios}
                form={form}
                label="Localização"
                placeholder="Localização"
                name="rg_pes"
                value={form.values.produto?.enderecamento}
                disabled={true}
              />
            </div>
          </div>
          <div className="md:flex w-full">
            <div className="field col-12 md:col-8">
              <TextInput
                camposObrigatorios={camposObrigatorios}
                form={form}
                label="Referência de fábrica"
                placeholder="Referência de fábrica"
                name="rg_pes"
                value={form.values.produto?.reffab_fpr }
                disabled={true}
              />
            </div>
            <div className="field col-12 md:col-2">
              <TextInput
                label="Valor de venda"
                disabled
                value={formatToCurrency(form.values.produto?.preco_pre)}
                iconLeft='pi-dollar'
                onClick={() => setVisibleValoresOutrasEmpresasModal(true) }

              />
            </div>
            <div className="field col-12 md:col-2">
              <TextInput
                label="Preço promocional"
                disabled
                value={formatToCurrency(form.values.produto?.prepro_pre)}
              />
            </div>
          </div>
          <div className="md:flex w-full">
            <div className="field col-12 md:col-12">
              <TextInput
                camposObrigatorios={camposObrigatorios}
                form={form}
                label="Observações"
                placeholder="Observações"
                name="rg_pes"
                value={form.values.produto?.obs_pro}
                disabled={true}
              />
            </div>
          </div>
        </div>

        <TabMenuContainer
          mostrarBotaoProximo={false}
        >
          <TabMenuPage header="ESTOQUE">
            <EstoqueList produtoEmpresa={form.values.dadosEstoque}/>
          </TabMenuPage>
          <TabMenuPage header="ORÇAMENTOS/O.S">
            <OrcamentoOSList
              orcamentoOS={form.values.qtdReservada}
              aplicada={aplicada}
              reservada={reservada}
              aRetirar={aRetirar}
              setAplicada={setAplicada}
              setReservada={setReservada}
              setARetirar={setARetirar}
            />
          </TabMenuPage>
          <TabMenuPage header="PEDIDOS DE COMPRA">
            <PedidoCompraList pedidoCompra={form.values.pedidoPeca}/>
          </TabMenuPage>
        </TabMenuContainer>

        <ValoresProdutoDasOutrasEmpresas
          visible={visibleValoresOutrasEmpresasModal}
          onHide={() => setVisibleValoresOutrasEmpresasModal(false)}
          produto={produtoParaVisualizarDados}
        />
        <DadosProdutoDasOutrasEmpresas
          visible={visibleDadosOutrasEmpresasModal}
          onHide={() => setVisibleDadosOutrasEmpresasModal(false)}
          produto={produtoParaVisualizarDados}
        />
        <PrecosEmpresasModal
          visible={visiblePrecosEmpresasModal}
          onHide={() => setVisiblePrecosEmpresasModal(false)}
          produto={produtoParaVisualizarDados}
        />

      </>

    )
  }
  return (
    <>
      {modal ? <Modal
        header={`Consulta de estoque ${form.values?.produto?.codigo_pro ? ' - ' + form.values?.produto?.codigo_pro : ''}`}
        visible={visible}
        onHide={hide}
        className="impressao-neg-modal"
      >{ComponentConsultaEstoque()}</Modal> : ComponentConsultaEstoque()}

    </>
  )
}

export default ConsultaEstoqueForm
