import React, { useEffect, useState } from 'react'
import BtnOption from '../../../../components/layout/BtnOption'
import { showWarnMessage } from '../../../../components/utils/Message'
import Modal from '../../../../components/utils/Modal'
import ConfigService from '../../../../services/configuracao/ConfigService'
import ReportService from '../../../../services/reports/ReportService'

const ImpressoesOrcamentoModal = ({ form, visibleImpressoesOrcamentoModal: visibleImpressoesOrcamentoModal, hide, operacaoNeg }) => {
  const [mostrarImpressaoOrcamentoReduzido, setMostrarImpressaoOrcamentoReduzido] = useState(false)
  const renderizarHTML = async (report, rota) => {
    const codigoOrcamento = form.values.codigo_opv
    let htmlContent = await ReportService.getHTML(codigoOrcamento, report, rota)

    htmlContent += `
    <script>
      window.onload = function() { window.print();}
    </script>`

    const blob = new Blob([htmlContent], { type: 'text/html' })

    const url = URL.createObjectURL(blob)

    window.open(url, '_blank')
  }

  useEffect(() => {
    getMostrarOrcamentoReduzido()
  }, [])

  const getMostrarOrcamentoReduzido = async () => {
    const config1280 = await ConfigService.getValor(1280)
    setMostrarImpressaoOrcamentoReduzido(config1280)
  }
  const imprimirOrcamentoHTML = async () => {
    try {
      renderizarHTML('orcamento', 'html')
    } catch (error) {
      console.error(error)
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar imprimir o orçamento!')
    }
  }
  const imprimirRomaneioHTML = async () => {
    if (!form.values.usuent_opv) {
      showWarnMessage('Entrega ainda não foi realizada!')
      return
    }
    try {
      renderizarHTML('romaneio', 'html')
    } catch (error) {
      console.error(error)
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar imprimir o romaneio!')
    }
  }
  const imprimirSeparacaoReduzidaHTML = async () => {
    try {
      renderizarHTML('separacaoReduzida', 'html')
    } catch (error) {
      console.error(error)
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar imprimir a separação de peça (reduzida)!')
    }
  }
  const imprimirOrcamentoReduzidoHTML = async () => {
    try {
      renderizarHTML('orcamentoReduzido', 'html')
    } catch (error) {
      console.error(error)
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar imprimir o orçamento (reduzido)!')
    }
  }
  const imprimirSeparacaoPecas = async () => {
    try {
      renderizarHTML('separacaoPeca', 'html')
    } catch (error) {
      console.error(error)
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar imprimir a separação de peças!')
    }
  }

  return (
    <>
      <Modal
        header="Impressões"
        visible={visibleImpressoesOrcamentoModal}
        onHide={hide}
        className="impressao-neg-modal"
      >
        <div className="impressao-neg-buttons">
          <BtnOption
            type="button"
            label={'Orçamento'}
            onClick={imprimirOrcamentoHTML}
          />
          {mostrarImpressaoOrcamentoReduzido
            ? <BtnOption
              type="button"
              label={'Orçamento (Reduzido)'}
              onClick={imprimirOrcamentoReduzidoHTML}
            />
            : '' }
          <BtnOption
            type="button"
            label={'Romaneio'}
            onClick={imprimirRomaneioHTML}
          />
          <BtnOption
            type="button"
            label={'Separação de peças'}
            onClick={imprimirSeparacaoPecas}
          />
          <BtnOption
            type="button"
            label={'Separação de peças (Reduzido)'}
            onClick={imprimirSeparacaoReduzidaHTML}
          />
        </div>
      </Modal>
    </>
  )
}

export default ImpressoesOrcamentoModal
